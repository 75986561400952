import React from "react";
import { graphql } from "gatsby";
import { LandingPageQueryQuery } from "../types/types.generated";
import { Seo } from "../components/Seo/Seo";
import classNames from "classnames";

type LandingPageProps = {
  data: LandingPageQueryQuery;
};

const LandingPage = ({ data }: LandingPageProps) => {
  const backgroundColor = data.prismicLandingPage?.data?.background_colour ? `bg-${data.prismicLandingPage?.data?.background_colour}` : 'bg-white';

  return (
    <div>
      <Seo title={data.prismicLandingPage?.data.title} className={backgroundColor} />
      {data.prismicLandingPage?.data.body?.html && (
        <div
          dangerouslySetInnerHTML={{
            __html: data.prismicLandingPage?.data.body?.html,
          }}
          className='prose mx-auto p-4'
        />
      )}
    </div>
  );
};

export default LandingPage;

export const LandingPageQuery = graphql`
  query LandingPageQuery($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      data {
        body {
          html
        }
        title
        background_colour
      }
    }
  }
`;
